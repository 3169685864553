html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
/* App.css */
.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
